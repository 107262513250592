
*{margin:0;padding:0;box-sizing: border-box;}


body{position: relative;}

.quiz-container {
  position: relative;
  color: #fff;
  border-radius: 10px;
  width: 100%;
  height:100vh;
 
}


.qoption button {
  margin-left: 20px;
}

.qoption button:first-child {
  margin-left: 0;
}

.uxdtlogo {
  position: relative;
  text-align: left;
}
.uxdtlogo span {
  position: relative;
  top: 8px;
  color: #fff;
  left: 5px;
}

.uxdtlogo span:before {
  position: absolute;
  left: -6px;
  width: 1px;
  height: 26px;
  background-color: #fafafa;
  content: '';
  top: -6px;
  z-index: 1;
}



.qwrap {
  margin-top: 25px;
}
.uxdtlogo img {
  width: 100px;
}

.uxdt_header {
  padding: 15px 0;
}
.uxdtcontact {
  text-align: right;
  color: #fff;
}



.question {
  margin-bottom: 20px;
}

.question h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.question ul {
  list-style-type: none;
  padding: 0;
}

.question li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.question img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}


.quiz-container ul {
  display: flex;
  gap: 10px;
  padding: 0;
}

.quiz-container ul li {
  list-style-type: none;
}

.comparison-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.comparison-item {
  text-align: center;
}

.comparison-item img {
  max-width: 200px;
  height: auto;
}

.answer-list img{
  width:100%;
  height:100%;
  border-radius: 7px;
}



.score-display {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.score-display span {
  background-color: #df8f28;
  padding: 0px 15px;
  border-radius: 15px;
  font-size: 18px;
  margin-top: 5px;
}

.score-display img {
  width: 30px;
}

/* video */

.app {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.answer-list {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  margin-top: 100px;
}

.css-12ogqma {
  display: flex;
  width: 450px;
  border-radius: 8px;
  transition: transform 0.2s;
  /* overflow: hidden; */
  cursor: pointer;
  touch-action: manipulation;
  margin: 15px;
}

.css-v2kfba {
  height: 100%;
  width: 100%;
}



.correct-1,.correct-2,.correct-3,.correct-4,.correct-5,.correct-6,.correct-7,.correct-8,.correct-9,.correct-10,.correct-11,.correct-12,.correct-13,.correct-14,.correct-15,.correct-16,.correct-17,.correct-18{
    transition: transform 0.2s;
    transform: translateX(240px);
    z-index: 7;
position: relative;
}

.wrong-1,.wrong-2,.wrong-3,.wrong-4,.wrong-5,.wrong-6,.wrong-7,.wrong-8,.wrong-9,.wrong-10,.wrong-11,.wrong-12,.wrong-13,.wrong-14,.wrong-15,.wrong-16,.wrong-17,.wrong-18{
  transition: transform 0.2s;
  transform: translateX(-240px);
  z-index: 6;
  overflow: hidden;
  position: relative;
}


.demo .wrong-1,.demo .wrong-2,.demo .wrong-3,.demo .wrong-4,.demo .wrong-5,.demo .wrong-6,.demo .wrong-7,.demo .wrong-8,.demo .wrong-9,.demo .wrong-10,.demo .wrong-11,.demo .wrong-12,.demo .wrong-13,.demo .wrong-14,.demo .wrong-15,.demo .wrong-16,.demo .wrong-17,.demo .wrong-18{
  overflow: visible;
  z-index: 8;
}


/* wrong */

 .new-wrong {
  z-index: 8;
  overflow: visible;
}

.demo .wrong-1.new-wrong,.demo .wrong-2.new-wrong,.demo .wrong-3.new-wrong,.demo .wrong-4.new-wrong,.demo .wrong-5.new-wrong,.demo .wrong-6.new-wrong,.demo .wrong-7.new-wrong,.demo .wrong-8.new-wrong,.demo .wrong-9.new-wrong,.demo .wrong-10.new-wrong,.demo .wrong-11.new-wrong,.demo .wrong-12.new-wrong,.demo .wrong-13.new-wrong,.demo .wrong-14.new-wrong,.demo .wrong-15.new-wrong,.demo .wrong-16.new-wrong,.demo .wrong-17.new-wrong,.demo .wrong-18.new-wrong {
  overflow: hidden;
  z-index: 6;
}


/* when click wrong 1st wrong qs */

.wrong-2.new-wrong,.wrong-3.new-wrong,.wrong-6.new-wrong,.wrong-10.new-wrong,.wrong-11.new-wrong,.wrong-15.new-wrong,.wrong-17.new-wrong,.wrong-18.new-wrong { transform: translateX(240px);}

.wrong-2.z,.wrong-3.z,.wrong-6.z,.wrong-10.z,.wrong-11.z,.wrong-15.z,.wrong-17.z,.wrong-18.z { transform: translateX(240px);}

.correct-2,.correct-3,.correct-6,.correct-10,.correct-11,.correct-15,.correct-17,.correct-18 { transform: translateX(-240px);}

/* when click wrong 10th wrong qs */


.feedback {
  position: absolute;
  z-index: 7;
  bottom: -13px;
  right: -170px;
  display: grid;
  align-items: center;
  grid-template-columns: 50px 150px;
  gap: 5px;
}

.quiz-container .feedback .correct-message{color:green;}

.quiz-container.demo .feedback .wrong-message{color:#fff;}

.wrong-message{color:#fff;}


.quiz-container.demo .feedback .wrong-message {
  opacity: 1;
  position: relative;
}
.dynamic-message.new-wrong-class .wrong-message {
  opacity: 1;
  width: 100%;
}
.dynamic-message {
  line-height: 20px;
  text-align: left;
  margin-left: 1px;
}
.comparison-item-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.comparison-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.compare-img {
  width: 200px;
  height: auto;
  transition: transform 0.5s ease;
}

.correct-img {
  transform: translateX(-50px); /* Slide correct image slightly left */
}

.wrong-img {
  transform: translateX(50px); /* Slide wrong image slightly right */
}

.comparison-item.correct:hover .correct-img,
.comparison-item.wrong:hover .wrong-img {
  transform: scale(1.1); /* Zoom in effect on hover */
}


.feedback .wrongs .css-1bkw144 {
  opacity: 0;
  animation: 0.3s ease 0.4s 1 normal forwards running animation-9nky3g;
}


/* progress bar */

.progress-container {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  max-width: 450px;
  height: 20px;
  border-radius: 15px;
  position: relative;
  margin:0px auto 0px;
}
button.btn.btn-primary {
  margin-left: 10px;
}
progress::-webkit-progress-value {
  background: #816e20;
}

progress::-moz-progress-bar {
  background: #09101e;
}

progress::-webkit-progress-bar {
  background: #09101e;
}

.progress-container progress {
  display: block;
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  transition: 0.5s;
  height:100%;
  width:100%;
  border-radius: 30px;
}
.progress-container span {
  color: #7f6d2a;
  position: absolute;
  bottom: -25px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  
}
.progress-container p {
  margin-bottom: 0;
  position: absolute;
  text-align: center;
}


.form-wrapper {
  max-width: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}
form.player-form {
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: left;
}
.form-wrapper label {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
}
.form-wrapper label input {
  padding: 5px;
  width: 100%;
}


.leaderboard-wrapper {
  max-width: 500px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  text-align: left;
}

.leaderboard-entry span.position {
  position: relative;
  padding: 0 7px;
}

.leaderboard-entry {
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: self-end;
}



.quiz-container h3 {
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
}


.total_score {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
}

.leader-board .leaderboard-entry {
  display: flex;
  margin-bottom: 3px;
  justify-content: space-between;
  gap:2px;
}
.leader-board {
  background-color: #ccc;
  padding: 10px;
}
.total_score  span {
  background-color: #42cded;
  font-size: 28px;
  font-weight: 700;
  padding: 3px 15px;
  margin: 1px;
}

.quiz-container button.btn.btn-info {
  margin: 20px auto 0;
  display: table;
}

.quiz-container {
  text-align: center;
}



.prev-next {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 15px;
  position: relative;
  margin: 0px auto 0px;
  max-width: 430px;
}

button.btn.custom-bg {
  background-color: #ede34f;
  font-size: 20px;
  font-weight: 600;
  border-radius: 30px;
  padding: 13px 30px;
  color:#000;
}
.btn-wrapper {
  position: absolute;
  left: 50%;
  bottom: 100px;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 55px;
}

.custom-mrgn{margin-top:48px;}


@keyframes animation-9nky3g {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
}
100% {
  opacity: 1;
  transform: scale(1);
}
}

@media(max-width:1650px){

  .answer-list {margin-top: 40px;}

  .custom-mrgn{margin-top:20px;}

  .custom-mrgn{margin-top:20px;}

  button.btn.custom-bg {padding: 10px 25px;}

.css-12ogqma { width: 350px; margin: 10px;}

.btn-wrapper { bottom: 50px; width: 100%; gap: 35px;}

.correct-1, .correct-2, .correct-3, .correct-4, .correct-5,.correct-6, .correct-7, .correct-8, .correct-9, .correct-10, .correct-11, .correct-12, .correct-13, .correct-14, .correct-15, .correct-16, .correct-17, .correct-18 { transform: translateX(185px);}

.wrong-1, .wrong-2, .wrong-3, .wrong-4, .wrong-5,.wrong-6, .wrong-7, .wrong-8, .wrong-9, .wrong-10, .wrong-11, .wrong-12, .wrong-13, .wrong-14, .wrong-15, .wrong-16, .wrong-17, .wrong-18 {transform: translateX(-185px);}


/* when click wrong 1st wrong qs */

.wrong-2.new-wrong,.wrong-3.new-wrong,.wrong-6.new-wrong,.wrong-10.new-wrong,.wrong-11.new-wrong,.wrong-15.new-wrong,.wrong-17.new-wrong,.wrong-18.new-wrong { transform: translateX(185px);}

.wrong-2.z,.wrong-3.z,.wrong-6.z,.wrong-10.z,.wrong-11.z,.wrong-15.z,.wrong-17.z,.wrong-18.z { transform: translateX(185px);}

.correct-2,.correct-3,.correct-6,.correct-10,.correct-11,.correct-15,.correct-17,.correct-18 { transform: translateX(-185px);}


}


@media(max-width:991px){
  .css-12ogqma { width: 350px;}
  .prev-next {margin: 20px auto 0px;max-width: 330px;}

.progress-container { max-width: 340px; margin: 25px auto 0px;}

button.btn.custom-bg { font-size: 16px; padding: 8px 15px;}


}

@media(max-width:767px){

  .form-wrapper {
    width: 75%;
    position: relative;
    height: initial;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
}

.leaderboard-wrapper {
  width: 75%;
  position: relative;
  height: initial;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}



  .quiz-container h3 {
    margin-bottom: 10px;
    font-size: 18px;
    text-align: center;
}




html,body{background:none; width: inherit; height: inherit;}
.answer-list {  margin: 0 auto; display: table;}

.correct-1, .correct-2, .correct-3, .correct-4, .correct-5,.correct-6, .correct-7, .correct-8, .correct-9, .correct-10, .correct-11, .correct-12, .correct-13, .correct-14, .correct-15, .correct-16, .correct-17, .correct-18 {
    transform: translateY(122px);
}

.wrong-1, .wrong-2, .wrong-3, .wrong-4, .wrong-5,.wrong-6, .wrong-7, .wrong-8, .wrong-9, .wrong-10, .wrong-11, .wrong-12, .wrong-13, .wrong-14, .wrong-15, .wrong-16, .wrong-17, .wrong-18 {
  transform: translateY(-121px);
}

.quiz_wrapper {
  position: relative;
  z-index: 1; /* Ensures the quiz is above the video */
}



/* when click wrong 1st wrong qs */

.wrong-2.new-wrong,.wrong-3.new-wrong,.wrong-6.new-wrong,.wrong-10.new-wrong,.wrong-11.new-wrong,.wrong-15.new-wrong,.wrong-17.new-wrong,.wrong-18.new-wrong { transform: translateY(122px);}

.wrong-2.z,.wrong-3.z,.wrong-6.z,.wrong-10.z,.wrong-11.z,.wrong-15.z,.wrong-17.z,.wrong-18.z { transform: translateY(122px);}

.correct-2,.correct-3,.correct-6,.correct-10,.correct-11,.correct-15,.correct-17,.correct-18 { transform: translateY(-121px);}



/* when click wrong 1st wrong qs 4*/






.prev-next {margin: -40px auto 0px;}

.btn-wrapper {
  position: relative;
  left: inherit;
  bottom: 0px;
  transform: inherit;
  width: 100%;
  display: block;
  flex-direction: column;
  gap: 55px;
}
.app {
  height: inherit;
}




.feedback {
  position: absolute;
  z-index: 7;
  bottom: -10px;
  right: -14px;
  display: grid;
  align-items: center;
  grid-template-columns: 50px;
  gap: 0px;
}
.dynamic-message {
  position: absolute;
  bottom: -45px;
  width: 170px;
  line-height: 20px;
  right: 0px;
  text-align: right;
}


}


@media(max-width:400px){
  
  .css-12ogqma { width: 330px;}

  .feedback {right: -7px;}

.correct-1, .correct-2, .correct-3, .correct-4, .correct-5,.correct-6, .correct-7, .correct-8, .correct-9, .correct-10, .correct-11, .correct-12, .correct-13, .correct-14, .correct-15, .correct-16, .correct-17, .correct-18 {
    transform: translateY(116px);
}

.wrong-1, .wrong-2, .wrong-3, .wrong-4, .wrong-5,.wrong-6, .wrong-7, .wrong-8, .wrong-9, .wrong-10, .wrong-11, .wrong-12, .wrong-13, .wrong-14, .wrong-15, .wrong-16, .wrong-17, .wrong-18 {
  transform: translateY(-114px);
}





/* when click wrong 1st wrong qs */

.wrong-2.new-wrong,.wrong-3.new-wrong,.wrong-6.new-wrong,.wrong-10.new-wrong,.wrong-11.new-wrong,.wrong-15.new-wrong,.wrong-17.new-wrong,.wrong-18.new-wrong { transform: translateY(116px);}

.wrong-2.z,.wrong-3.z,.wrong-6.z,.wrong-10.z,.wrong-11.z,.wrong-15.z,.wrong-17.z,.wrong-18.z { transform: translateY(116px);}

.correct-2,.correct-3,.correct-6,.correct-10,.correct-11,.correct-15,.correct-17,.correct-18 { transform: translateY(-114px);}


}